::selection {
  background: #fddf92;
}

.us-button {
  color: #483400;
  border: 2px solid #fbbf24;
  border-radius: 3px;
  align-items: center;
  padding: 8px 16px;
  font-family: Fira Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  display: inline-flex;
}

.us-button .icon {
  margin-right: 8px;
}

.us-button:hover {
  color: #483400;
  background: #fccc50;
  border-color: #fccc50;
}

.us-button.primary {
  color: #483400;
  background: #fbbf24;
}

.us-button.primary:hover {
  background: #fccc50;
  border-color: #fccc50;
}

.zigzag {
  height: 13px;
  background-image: url("zigzag.8708e715.svg");
  background-position: center;
  background-repeat: repeat-x;
  background-size: contain;
  border: none;
  margin: 8px 0;
  display: block;
}

section.hero {
  max-width: 864px;
  margin: 0 auto;
  padding: 128px 0;
  position: relative;
}

@media (max-width: 950px) {
  section.hero {
    padding: 64px 24px;
  }
}

section.hero h1 {
  color: #353535;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 1.2;
}

section.hero h1 em {
  color: #fbbf24;
  font-style: normal;
}

@media (max-width: 950px) {
  section.hero h1 {
    text-align: left;
    font-size: 30px;
  }
}

section.hero p {
  max-width: 520px;
  color: #616161;
  text-align: center;
  margin: 48px auto 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 950px) {
  section.hero p {
    text-align: left;
    margin: 24px 0 0;
    font-size: 16px;
  }
}

section.hero .buttons {
  flex-flow: wrap;
  justify-content: center;
  gap: 24px;
  margin: 48px 0 0;
  display: flex;
}

@media (max-width: 950px) {
  section.hero .buttons {
    justify-content: left;
    margin-top: 24px;
  }
}

section.hero .hero-particles {
  z-index: -10;
  display: block;
  position: relative;
}

@media (max-width: 1350px) {
  section.hero .hero-particles {
    display: none;
  }
}

section.hero .hero-particles img {
  position: absolute;
  top: 0;
  left: 0;
}

section.wegrow {
  max-width: 864px;
  margin: 0 auto;
  padding: 128px 0;
  position: relative;
}

@media (max-width: 950px) {
  section.wegrow {
    padding: 64px 24px;
  }
}

section.wegrow .section-title {
  color: #353535;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 58px;
  font-weight: 700;
  line-height: 1.2;
  display: flex;
}

section.wegrow .section-title img {
  margin-left: -78px;
  margin-right: 48px;
}

@media (max-width: 950px) {
  section.wegrow .section-title {
    flex-direction: row-reverse;
    justify-content: left;
    font-size: 30px;
  }

  section.wegrow .section-title img {
    width: 50px;
    margin-left: 24px;
    margin-right: 0;
  }
}

section.wegrow .section-text {
  max-width: 545px;
  color: #616161;
  text-align: center;
  margin: 48px auto 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 950px) {
  section.wegrow .section-text {
    text-align: left;
    margin: 24px 0 0;
    font-size: 16px;
  }
}

section.wegrow .counters {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  margin-top: 48px;
  display: flex;
}

@media (max-width: 950px) {
  section.wegrow .counters {
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    margin-top: 24px;
  }
}

section.wegrow .counters .counter {
  width: 210px;
  flex-direction: column-reverse;
  display: flex;
}

@media (max-width: 950px) {
  section.wegrow .counters .counter {
    width: auto;
  }
}

section.wegrow .counters .counter .counter-value {
  color: #fbbf24;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 48px;
  font-weight: 700;
  display: flex;
}

section.wegrow .counters .counter .counter-value img {
  margin-right: 16px;
}

@media (max-width: 950px) {
  section.wegrow .counters .counter .counter-value {
    justify-content: left;
    font-size: 30px;
  }

  section.wegrow .counters .counter .counter-value img {
    width: 28px;
  }
}

section.wegrow .counters .counter .counter-label {
  color: #616161;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 950px) {
  section.wegrow .counters .counter .counter-label {
    text-align: left;
    color: #483400;
    font-size: 18px;
  }
}

section.showcase {
  background: #e8e8e8;
  flex-flow: row;
  align-items: flex-start;
  gap: 32px;
  padding: 64px;
  display: flex;
  overflow-x: auto;
}

@media (max-width: 950px) {
  section.showcase {
    gap: 16px;
    padding: 32px;
  }
}

@media (min-width: 950px) {
  section.showcase::-webkit-scrollbar {
    height: 5px;
    background: none;
  }

  section.showcase::-webkit-scrollbar-thumb {
    background: #0000001a;
    border-radius: 3px;
  }
}

section.showcase .card {
  width: 380px;
  background: #fff;
  border-radius: 3px;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: 0 4px #a5a5a5;
}

@media (max-width: 950px) {
  section.showcase .card {
    width: 320px;
  }
}

section.showcase .card .card-image {
  width: 380px;
  height: 215px;
  object-position: center;
  object-fit: cover;
  display: block;
}

@media (max-width: 950px) {
  section.showcase .card .card-image {
    width: 320px;
    height: 181px;
  }
}

section.showcase .card .card-body {
  padding: 32px;
}

@media (max-width: 950px) {
  section.showcase .card .card-body {
    padding: 16px;
  }
}

section.showcase .card .card-title {
  color: #353535;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 950px) {
  section.showcase .card .card-title {
    font-size: 18px;
  }
}

section.showcase .card .card-list {
  margin: 16px 0 0;
  padding: 0 0 0 32px;
  list-style-type: none;
}

@media (max-width: 950px) {
  section.showcase .card .card-list {
    margin-top: 8px;
    padding-left: 24px;
  }
}

section.showcase .card .card-list li {
  color: #616161;
  font-family: Fira Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

section.showcase .card .card-list li:before {
  content: "✓";
  width: 1.2em;
  color: #fbbf24;
  margin-left: -1.2em;
  display: inline-block;
}

@media (max-width: 950px) {
  section.showcase .card .card-list li {
    font-size: 16px;
  }
}

section.showcase .card .card-button {
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
  display: flex;
}

@media (max-width: 950px) {
  section.showcase .card .card-button {
    margin-top: 24px;
    margin-bottom: 8px;
  }
}

section.showcase .card .card-button a {
  color: #483400;
  text-transform: uppercase;
  letter-spacing: .14em;
  flex-direction: row;
  align-items: center;
  font-family: Fira Sans, sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

section.showcase .card .card-button a:hover {
  color: #fbbf24;
}

section.showcase .card .card-button a:hover img {
  filter: brightness(0) saturate() invert(80%) sepia(80%) saturate(2419%) hue-rotate(335deg) brightness(98%) contrast();
}

section.showcase .card .card-button a img {
  height: 1em;
  width: 1em;
  margin-right: 16px;
}

section.platform {
  padding: 128px 32px;
  position: relative;
}

@media (max-width: 950px) {
  section.platform {
    padding: 64px 24px;
  }
}

section.platform .section-title {
  color: #353535;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
}

section.platform .section-title em {
  text-underline-offset: 10px;
  font-style: normal;
  -webkit-text-decoration: underline #fbbf24;
  text-decoration: underline #fbbf24;
  text-decoration-thickness: 5px;
}

@media (max-width: 950px) {
  section.platform .section-title {
    text-align: left;
    max-width: 300px;
    font-size: 30px;
  }

  section.platform .section-title em {
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }
}

section.platform .section-text {
  max-width: 494px;
  color: #616161;
  text-align: center;
  margin: 48px auto 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 950px) {
  section.platform .section-text {
    text-align: left;
    margin: 24px 0 0;
    font-size: 16px;
  }
}

section.platform .cards {
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 48px;
  margin-top: 48px;
  display: flex;
}

@media (max-width: 950px) {
  section.platform .cards {
    flex-direction: column;
    gap: 32px;
    margin-top: 24px;
  }
}

section.platform .cards .card {
  max-width: 375px;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  flex-direction: column;
  padding: 32px;
  display: flex;
  box-shadow: 0 4px #e8e8e8;
}

@media (max-width: 950px) {
  section.platform .cards .card {
    padding: 16px;
  }
}

section.platform .cards .card .card-title {
  color: #353535;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 24px;
  font-weight: 400;
  display: flex;
}

section.platform .cards .card .card-title .card-icon {
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border: 2px solid #fbbf24;
  border-radius: 3px;
  margin-right: 16px;
  padding: 0;
  position: relative;
}

section.platform .cards .card .card-title .card-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.9);
}

section.platform .cards .card .card-text {
  color: #616161;
  flex-grow: 1;
  margin: 16px 0 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 950px) {
  section.platform .cards .card .card-text {
    font-size: 14px;
  }
}

section.platform .cards .card .card-button {
  margin-top: 32px;
}

@media (max-width: 950px) {
  section.platform .cards .card .card-button {
    text-align: right;
    margin-top: 24px;
    margin-bottom: 8px;
    padding-right: 8px;
  }
}

section.platform .cards .card .card-button a {
  color: #483400;
  text-transform: uppercase;
  letter-spacing: .14em;
  font-family: Fira Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}

section.platform .cards .card .card-button a:hover {
  color: #fbbf24;
}

section.platform .cards .card .card-button a:hover img {
  filter: brightness(0) saturate() invert(80%) sepia(80%) saturate(2419%) hue-rotate(335deg) brightness(98%) contrast();
}

section.indie {
  padding: 128px 32px;
  position: relative;
}

@media (max-width: 950px) {
  section.indie {
    padding: 64px 24px;
  }
}

section.indie .section-title {
  color: #353535;
  text-align: left;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
}

section.indie .section-title em {
  text-underline-offset: 10px;
  font-style: normal;
  -webkit-text-decoration: underline #fbbf24;
  text-decoration: underline #fbbf24;
  text-decoration-thickness: 5px;
}

@media (max-width: 950px) {
  section.indie .section-title {
    text-align: left;
    font-size: 30px;
  }

  section.indie .section-title em {
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }
}

section.indie .horizontal-layout {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 64px;
  margin: 0 auto;
  display: flex;
}

@media (max-width: 950px) {
  section.indie .horizontal-layout {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

section.indie .horizontal-layout .column {
  flex-grow: 0;
  flex-shrink: 1;
}

section.indie .horizontal-layout .column.photo-column {
  width: 500px;
}

section.indie .horizontal-layout .column.text-column {
  width: 600px;
}

@media (max-width: 950px) {
  section.indie .horizontal-layout .column {
    width: auto !important;
  }
}

section.indie .about-me-photo {
  width: 100%;
  filter: brightness(1.2) contrast(1.1) sepia(.5);
  border-radius: 2px;
}

section.indie .section-text {
  color: #616161;
  text-align: left;
  font-family: Fira Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 950px) {
  section.indie .section-text {
    text-align: left;
    margin: 24px 0 0;
    font-size: 16px;
  }
}

section.indie .section-text.author {
  text-align: right;
  font-style: italic;
}

section.discord {
  padding: 128px 32px;
  position: relative;
}

@media (max-width: 950px) {
  section.discord {
    padding: 64px 24px;
  }
}

section.discord .section-title {
  color: #353535;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.3;
}

section.discord .section-title em {
  color: #fbbf24;
  font-style: normal;
}

@media (max-width: 950px) {
  section.discord .section-title {
    text-align: left;
    font-size: 24px;
  }
}

section.discord .discord-button {
  text-align: center;
  margin: 0;
  padding: 32px 0;
}

@media (max-width: 950px) {
  section.discord .discord-button {
    text-align: left;
    padding: 16px 0;
  }
}

section.discord .discord-button a {
  background: #fbbf24;
  border-radius: 3px;
  margin: 0;
  padding: 16px 48px;
  font-size: 0;
  display: inline-block;
  box-shadow: 0 4px #b67300;
}

section.discord .discord-button a:hover {
  background: #fccc50;
  transform: translate(0, 3px);
  box-shadow: 0 1px #b67300;
}

section.discord .discord-button a img {
  filter: invert();
}

/*# sourceMappingURL=index.35178aff.css.map */
