////////////
// Layout //
////////////

$spacing-4: 4px;
$spacing-8: 8px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-48: 48px;
$spacing-64: 64px;
$spacing-96: 96px;
$spacing-128: 128px;
$spacing-192: 192px;
$spacing-256: 256px;
$spacing-384: 384px;
$spacing-512: 512px;
$spacing-640: 640px;
$spacing-768: 768px;


////////////////
// Typography //
////////////////

$font-family: "Fira Sans", sans-serif;
$font-family-code: "Fira Code", monospace;

$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px; // default text size
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-30: 30px;
$font-size-36: 36px;
$font-size-48: 48px;
$font-size-58: 58px;
$font-size-64: 64px;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;


////////////
// Colors //
////////////

// primary

$color-primary-900: #483400; // primary colored text
$color-primary-700: #b67300;
$color-primary-500: #fbbf24; // THE primary color (text, accents, buttons)
$color-primary-300: #fccc50; // glare over primary (lighetend accent)
$color-primary-100: #fddf92; // tag background

$color-primary-dark:    $color-primary-900;
$color-primary-darker:  $color-primary-700;
$color-primary:         $color-primary-500;
$color-primary-lighter: $color-primary-300;
$color-primary-light:   $color-primary-100;

// gray

$color-gray-800: #353535; // THE gray (logo, heading text)
$color-gray-600: #616161; // regular text color
$color-gray-400: #a5a5a5;
$color-gray-100: #e8e8e8; // light background objects
$color-gray-50:  #f4f2f1; // gray background surface
$color-gray-10:  #fbfaf9; // light gray background surface

$color-gray:         $color-gray-800;
$color-gray-lighter: $color-gray-600;
$color-gray-light:   $color-gray-100;

$color-text-heading:      $color-gray-800;
$color-text-body:         $color-gray-600;
$color-text-over-primary: $color-primary-900;
